import * as types from "./types";
import { createReducer } from "../../utils";

/* State shape
{
    session: object
}
*/

const initialState = {
  isAuthenticated: false,
  redirectAfterLogin: null,
  token: window.localStorage.getItem("token"),
  refreshToken: window.localStorage.getItem("refresh_token"),
  isLoading: false,
  establishment: null,
};

const sessionReducer = createReducer(initialState)({
  [types.GET_ESTABLISHMENT]: (state, action) => {
    window.localStorage.setItem("token", action.payload.token);
    window.localStorage.setItem("refresh_token", action.payload.refreshToken);
    return {
      ...state,
      establishment: action.payload.id,
      token: action.payload.token,
      refreshToken: action.payload.refreshToken,
    };
  },
  [types.LOGIN]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [types.LOGIN_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },

  [types.LOGIN_COMPLETED]: (state, action) => {
    return {
      ...state,
      redirectAfterLogin: action.payload.redirectUrl,
      isAuthenticated: true,
      isLoading: false,
      token: action.payload.result,
    };
  },

  [types.LOGOUT]: (state, action) => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("refresh_token");
    return initialState;
  },
});

export default sessionReducer;
