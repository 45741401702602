import axios from "axios";
import { fetch } from "../utils";
import { notificationsOperations } from "state/ducks/notifications";
import { store } from "state/store";

const appEnv = process.env.REACT_APP_ENV;
const baseUrl = process.env.REACT_APP_LEMOULIN_API_URL;
const apiKey = process.env.REACT_APP_LEMOULIN_API_KEY;
const basciClientID = process.env.REACT_APP_LEMOULIN_BASIC_ID;
const basicClientSecret = process.env.REACT_APP_LEMOULIN_BASIC_SECRET;
const tokenUrl = baseUrl + "/login/bo";
const refreshTokenUrl = baseUrl + "/token/refresh";

let isRefreshing = false;
let refreshSubscribers = [];

function onRefreshed(token) {
  refreshSubscribers.forEach(callback => callback(token));
  refreshSubscribers = [];
}

function addRefreshSubscriber(callback) {
  refreshSubscribers.push(callback);
}

function requestHeaders(tokenAuth) {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  if (appEnv === 'staging') {
    const basicAuthToken = btoa(`${basciClientID}:${basicClientSecret}`);
    headers.Authorization = `Basic ${basicAuthToken}`;
  }

  if (tokenAuth) {
    headers = {
      ...headers,
      'X-Custom-Authorization': `Bearer ${tokenAuth}`
    };
  } else if (window.localStorage.getItem("token")) {
    const token = window.localStorage.getItem("token");
    headers = {
      ...headers,
      'X-Custom-Authorization': `Bearer ${token}`
    };
  }

  return headers;
}

const apiService = () => next => action => {
  const result = next(action);
  if (!action.meta || !action.meta.async) {
    return result;
  }

  if (action.meta.auth) {
    let axiosInstance = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    });
    console.log(appEnv)
    if (appEnv === 'staging') {
      const basicAuthToken = btoa(`${basciClientID}:${basicClientSecret}`);
      axiosInstance = axios.create({
        headers: {
          'Authorization': `Basic ${basicAuthToken}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      });
    }

    const data = {
      username: action.meta.body.username,
      password: action.meta.body.password
    };

    return axiosInstance.post(tokenUrl, data).then(
      res => {
        window.localStorage.setItem("token", res.data.access_token);
        window.localStorage.setItem("refresh_token", res.data.refresh_token);
        handleResponse(
          {
            data: {
              result: res.data.access_token
            }
          },
          action,
          next
        );
      },
      err => {
        handleErrors(err, action, next);
      }
    );
  } else {
    let { path, method, body, params } = action.meta;

    if (!path) {
      throw new Error(`'path' not specified for async action ${action.type}`);
    }

    let url = `${baseUrl}${path}?apiKey=${apiKey}`;

    if (method === "GET") {
      body = params;
    }

    let tokenAuth = store.getState().session.token;

    return fetch(url, method, body, tokenAuth).then(
      res => handleResponse(res, action, next),
      err => handleErrors(err, action, next)
    );
  }
};

export default apiService;

function handleErrors(err, action, next) {
  let payload = {};
  if (action.payload) {
    payload = action.payload;
  }
  payload.error = err;

  if (err?.response?.status === 401 && !action.meta._retry) {
    const originalRequest = err.config;
    const localStorage = window.localStorage;
    if (!isRefreshing) {
      isRefreshing = true;
      const refreshToken = localStorage.getItem('refresh_token');
      const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      });
      if (appEnv === 'staging') {
        const basicAuthToken = btoa(`${basciClientID}:${basicClientSecret}`);
        axiosInstance.defaults.headers['Authorization'] = `Basic ${basicAuthToken}`;
      }

      if (refreshToken === null) {
        isRefreshing = false;
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        store.dispatch(notificationsOperations.getResponseError("Accès non autorisé, veuillez vous deconnecter puis vous reconnecter."));
        return Promise.reject("Accès non autorisé");
      }
      return axiosInstance.post(refreshTokenUrl, { refresh_token: refreshToken }).then(
        (res) => {
          isRefreshing = false;
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('refresh_token', res.data.refresh_token);
          onRefreshed(res.data.token);
          originalRequest.headers['X-Custom-Authorization'] = `Bearer ${res.data.token}`;
          return axios(originalRequest).then(
            res => handleResponse(res, action, next),
            err => handleErrors(err, action, next)
          );
        },
        (refreshError) => {
          isRefreshing = false;
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          store.dispatch(notificationsOperations.getResponseError(refreshError));
          return Promise.reject(refreshError);
        }
      );
    } else {
      return new Promise((resolve) => {
        addRefreshSubscriber((token) => {
          originalRequest.headers['X-Custom-Authorization'] = `Bearer ${token}`;
          resolve(axios(originalRequest).then(
            res => handleResponse(res, action, next),
            err => handleErrors(err, action, next)
          ));
        });
      });
    }
  }

  next({
    type: `${action.type}_FAILED`,
    payload: payload,
    meta: action.meta
  });

  store.dispatch(notificationsOperations.getResponseError(err));
  return Promise.reject(err);
}

function handleResponse(res, action, next) {
  let payload = {};
  if (action.payload) {
    payload = action.payload;
  }
  payload.result = res.data.result;

  if (res.data.total) {
    payload.total = res.data.total;
  }

  next({
    type: `${action.type}_COMPLETED`,
    payload: payload,
    meta: action.meta
  });
  return res;
}
