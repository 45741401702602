import * as types from "./types";

// Action de se connecter
export const login = (username, password, redirectUrl) => ({
  type: types.LOGIN,
  meta: {
    async: true,
    auth: true,
    body: {
      username: username,
      password: password,
    },
  },
  payload: {
    redirectUrl,
  },
});

export const getEstablishment = (id, token, refreshToken) => ({
  type: types.GET_ESTABLISHMENT,
  payload: {
    id,
    token,
    refreshToken
  },
});

export const logout = () => ({
  type: types.LOGOUT,
});

export const initializeSession = () => ({
  type: types.INITIALIZE,
});
