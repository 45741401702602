import axios from "axios";
import param from "jquery-param";

const appEnv            = process.env.REACT_APP_ENV;
const basciClientID     = process.env.REACT_APP_LEMOULIN_BASIC_ID;
const basicClientSecret = process.env.REACT_APP_LEMOULIN_BASIC_SECRET;

export default (url, method, body, tokenAuth) => {
  function requestHeaders() {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
    if (appEnv === 'staging') {
      const basicAuthToken = btoa(`${basciClientID}:${basicClientSecret}`);
      headers.Authorization = `Basic ${basicAuthToken}`;
    }
    
    if (tokenAuth) {
      headers = {
        ...headers,
        'X-Custom-Authorization': `Bearer ${tokenAuth}`
      }
    }
    else if (window.localStorage.getItem("token")) {
      const token = window.localStorage.getItem("token");
      headers = {
        ...headers,
        'X-Custom-Authorization': `Bearer ${token}`
      }
    }

    return headers;
  }

  return axios({
    method: method,
    url: method === "GET" && body !== undefined ? `${url}&${param(body)}` : url,
    headers: requestHeaders(),
    data: method !== "GET" ? JSON.stringify(body) : null
  });

};
