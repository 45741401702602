import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import Error from "views/universal/Errors/Api/Error";
import { sessionOperations } from "state/ducks/session";

const Layout = (props) => {
  const dispatch = useDispatch();
  const [jwtToken, setJwtToken] = useState(null);
  const [jwtRefreshToken, setJwtRefreshToken] = useState(null);
  const [establishmentId, setEstablishmentId] = useState(null);

  useEffect(() => {
    console.log("Requesting JWT...");
    window.parent.postMessage('get_jwt', `${process.env.REACT_APP_LEMOULIN_BO_URL}`);

    const handleMessage = (event) => {
      if (event.origin !== `${process.env.REACT_APP_LEMOULIN_BO_URL}`) return;
      if (event.data.jwtToken) setJwtToken(event.data.jwtToken);
      if (event.data.jwtRefreshToken) setJwtRefreshToken(event.data.jwtRefreshToken);
      if (event.data.establishmentId) setEstablishmentId(event.data.establishmentId);
    };

    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, []);

  useEffect(() => {
    if (establishmentId && jwtToken && jwtRefreshToken) {
      dispatch(sessionOperations.getEstablishment(establishmentId, jwtToken, jwtRefreshToken));
    }
  }, [establishmentId, jwtToken, jwtRefreshToken, dispatch]);

  return (
    <>
      {jwtToken ? (
        <div>
          <Error />
          {props.children}
        </div>
      ) : (
        <div className="text-center pt-4">
          <img
            src="https://lemoulin.fr/wp-content/uploads/2018/10/le-moulin-salades-produits-frais.png"
            alt="Logo Le Moulin"
          />
          <br />
          <br />
          Vous n'avez pas accès à cette page.
        </div>
      )}
    </>
  );
};

Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;
