import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import moment from "moment";
import styled from "@emotion/styled";
import { storeOperations, storeSelector } from "state/ducks/store";
import PdfStock from "./PdfStock";

const PdfButton = props => {
  const { establishmentId, types, categoriesFilter } = props;

  const [loadPdf, setLoadPdf] = useState(false);
  // utile pour calculer les dates
  const [weekPrinted, setWeekPrinted] = useState(0);

  // Cherche les stocks et le détail de l'établissement
  const forecastStocks = useSelector(state => {
    return storeSelector.getForecastStocksPrintSelector(state);
  });
  const establishment = useSelector(state => state.session.establishment);

  const dispatch = useDispatch();

  /**
   * Lance la requete pour les forecast stock
   */
  const generatePdf = week => {
    setWeekPrinted(week);
    let categoriesTransform = [];
    if (categoriesFilter) {
      categoriesFilter.forEach((category, key) => {
        categoriesTransform = [...categoriesTransform, category.value];
      });
    }

    setLoadPdf(true);
    dispatch(
      storeOperations.getForecastStocks(
        establishmentId,
        types,
        categoriesTransform,
        [week],
        [1, 2, 3, 4, 5],
        0,
        null,
        true,
        true
      )
    );
  };

  useEffect(() => {
    if (
      loadPdf === true &&
      forecastStocks.printData &&
      forecastStocks.printData.length > 0 &&
      forecastStocks.isLoading === false
    ) {
      const weeknumber = moment().week();
      const start = moment()
        .day(1)
        .week(weeknumber + weekPrinted);
      const end = moment()
        .day(7)
        .week(weeknumber + weekPrinted);
      const startFormattedFileName = moment(start).format("DD-MM-YYYY");
      const endFormattedFileName = moment(end).format("DD-MM-YYYY");
      const fileName =
        "besoin-produit-" +
        (establishment === 1 ? "L": "V")  +
        "-du-" +
        startFormattedFileName +
        "-au-" +
        endFormattedFileName +
        ".pdf";
      const blob = pdf(
        <>
        <PdfStock
          stocksProduct={forecastStocks}
          start={startFormattedFileName}
          end={endFormattedFileName}
        />
        </>
      ).toBlob();
      blob.then(pdfBlob => {
        setLoadPdf(false);
        saveAs(pdfBlob, fileName);
      });
    }
  }, [loadPdf, establishment, forecastStocks, weekPrinted]);

  return (
    <div>
      {!loadPdf ? (
        <>
          <Item onClick={() => generatePdf(0)}>Semaine en cours</Item>
          <Item onClick={() => generatePdf(1)}>Semaine + 1</Item>
          <Item onClick={() => generatePdf(2)}>Semaine + 2</Item>
        </>
      ) : (
        "Géneration du pdf en cours..."
      )}
    </div>
  );
};

const Item = styled(Dropdown.Item)`
  border-bottom: solid 1px #ccc;
  &:hover {
    background-color: #ddd;
  }
`;

PdfButton.propTypes = {
  establishmentId: PropTypes.string, // Id de l'établissent
  types: PropTypes.array, // Liste des types d'activité pour les stocks
  categoriesFilter: PropTypes.array // La valeur du filtre catégorie
};

export default PdfButton;
